@import './mixins';

.ReactModal__Overlay {
   opacity: 0;
   transition: opacity 0.1s ease-in-out;
}

.ReactModal__Overlay--after-open {
   opacity: 1;
   transition: opacity 0.15s ease-in-out;
}

.ReactModal__Overlay--before-close {
   opacity: 0;
   transition: opacity 0.15s ease-in-out;
}

.modal {
   background-color: white;
   width: 500px;
   min-height: 500px;
   max-height: 680px;
   box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15);
   border: none;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   margin: 0 auto;
   border-radius: 0;
   outline: none;
   overflow: auto;
}

@include responsive(mobile) {
   .modal {
      width: 95%;
   }
}

@include responsive(small-mobile) {
   input::placeholder {
      font-size: 13px;
      position: absolute;
      top: 11px;
      left: 5px;
   }

   .pincode-input-text {
      width: 45px !important;
      height: 45px !important;
   }
}
