.checked {
   &::after {
      transition: all 0.25s linear;
      -webkit-transition: background 0.25s linear;
      animation: bounce 0.3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
   }
}

@-webkit-keyframes bounce {
   0% {
      // transform: scale(0.2);
      opacity: 0;
   }
   100% {
      // transform: scale(1);
      opacity: 1;
   }
}
@keyframes bounce {
   0% {
      // transform: scale(0.2);
      opacity: 0;
   }
   100% {
      // transform: scale(1);
      opacity: 1;
   }
}
