@import '../../../assets/styles/mixins';

.pricingCard {
   width: 100%;
   display: flex;
   flex-direction: row;
   cursor: pointer;
   // transition: all 0.2s ease;

   .checkboxWrap {
      @include responsive(mobile) {
         flex: 0 0 10%;
      }
   }

   .pricingDataWrap {
      position: relative;
      flex: 3 1 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
   }
}

.pricingDataUncheckable {
   padding-left: 0rem !important;
   padding: 0rem;
   cursor: default;
   &:hover {
      background-color: white !important;
   }
}
