.errorMessageWrap {
   height: 100vh;
   width: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 30px;
   .errorDescription {
      width: 70%;
      margin: 0 auto;
      line-height: normal;
      text-align: center;
      a {
         font-weight: 600;
         color: #d37b0d;
      }
   }
}
