@import '../../../assets/styles/mixins';

.activeServicesModal {
   position: relative;
}

.modalContentWrap {
   padding: 3rem;
   padding-bottom: 0;
   background-color: rgb(255, 255, 255);
   height: 100%;
   width: 100%;
   @include responsive(mobile) {
      padding: 4rem 1.5rem;
   }

   .tabsHeader {
      display: flex;
   }
   .tabsContent {
      margin-top: 2rem;
   }

   .tabsInputWrap {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      p {
         font-size: 0.8rem;
         line-height: 1rem;
      }
   }
}

.closeModalIcon {
   width: 12px;
   position: absolute;
   top: 1rem;
   right: 1rem;
   cursor: pointer;
   z-index: 10;
}

.disabled {
   pointer-events: none;
   color: gray;
   opacity: 0.6;
}

@include responsive(small-mobile) {
   .modalContentWrap {
      padding: 2rem;
   }
}
