@import '../../../assets/styles/mixins';
@import '../../../assets/styles/mixins';

.serviceRow {
   &:not(:last-child) {
      padding: 0 0 3rem;
   }
}

.serviceRowSlider {
   padding: 0 0 3rem !important;
}

.hidden {
   display: none;
}

.serviceRowGrid {
   display: grid;
   margin-bottom: 1rem;
   grid-gap: 2rem;

   &.col-2 {
      @extend .serviceRowGrid;
      grid-template-columns: repeat(2, 1fr);
      @include responsive(laptop) {
         grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(tablet) {
         grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(mobile) {
         grid-template-columns: repeat(1, 1fr);
      }
   }

   &.col-3 {
      @extend .serviceRowGrid;
      grid-template-columns: repeat(3, 1fr);
      // @include responsive(laptop) {
      //    grid-template-columns: repeat(2, 1fr);
      // }
      @include responsive(tablet) {
         grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(mobile) {
         grid-template-columns: repeat(1, 1fr);
      }
   }

   &.col-4 {
      @extend .serviceRowGrid;
      grid-template-columns: repeat(4, 1fr);
      // @include responsive(laptop) {
      //    grid-template-columns: repeat(3, 1fr);
      // }
      @include responsive(tablet) {
         grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(mobile) {
         grid-template-columns: repeat(1, 1fr);
      }
   }

   &.col-5 {
      @extend .serviceRowGrid;
      grid-template-columns: repeat(5, 1fr);
      // @include responsive(laptop) {
      //    grid-template-columns: repeat(4, 1fr);
      // }
      @include responsive(tablet) {
         grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(mobile) {
         grid-template-columns: repeat(1, 1fr);
      }
   }
   &.col-6 {
      @extend .serviceRowGrid;
      grid-template-columns: repeat(6, 1fr);
      @include responsive(laptop) {
         grid-template-columns: repeat(5, 1fr);
      }
      @include responsive(tablet) {
         grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(mobile) {
         grid-template-columns: repeat(1, 1fr);
      }
   }
}

.newServicesWrap {
   position: relative;
   height: 100%;
   width: auto;
   .newServiceSlider {
      padding-bottom: 2rem;
   }
}

.newServiceImg {
   height: 200px;
   width: 100%;
   object-fit: cover;
   object-position: center;
   border-radius: 20px;
   box-shadow: 0 10px 10px #0000002e, 0 10px 30px #00000026;
}
