/* RIBBON */
.ribbon-box {
   position: relative;
}

/* common */
.ribbon {
   width: 130px;
   height: 130px;
   overflow: hidden;
   position: absolute;
   z-index: 1000;
   /* display: block !important; */
}
.ribbon::before,
.ribbon::after {
   position: absolute;
   z-index: -1;
   content: '';
   display: block;
   border: 5px solid black;
}
.ribbon span {
   position: absolute;
   display: block;
   width: 225px;
   padding-left: 20px;
   padding: 15px 0;
   background-color: var(--color);
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
   color: #fff;
   font: 700 16px/1 'Lato', sans-serif;
   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
   text-transform: uppercase;
   text-align: center;
   color: black;
}

/* top left*/
.ribbon.left {
   top: -10px;
   left: -10px;
}
.ribbon.left::before,
.ribbon.left::after {
   border-top-color: transparent;
   border-left-color: transparent;
}
.ribbon.left::before {
   top: 0px;
   right: 2px;
}
.ribbon.left::after {
   bottom: 2px;
   left: 0px;
}
.ribbon.left span {
   right: -25px;
   top: 30px;
   transform: rotate(-45deg);
   padding-left: 20px;
}

.ribbon.right {
   top: -10px;
   right: -10px;
}

.ribbon.right::before {
   top: 0;
   left: 0;
}

.ribbon.right::after {
   bottom: 0;
   right: 0;
}

.ribbon.right span {
   left: -28px;
   top: 30px;
   transform: rotate(45deg);
}

.ribbon.right::after {
   border-top-color: transparent;
   border-right-color: transparent;
}

/*FLAG*/

.flag {
   padding: 20px;
   position: absolute;
   z-index: 1000;
   top: -20px;
}

.flag span {
   color: white;
   font-size: 1.2rem;
   font-weight: 600;
   position: relative;
}

.flag.right {
   right: -10px;
}

.flag.left {
   left: -10px;
}

.flag.left::before,
.flag.right::before {
   content: '';
   height: 55px;
   position: absolute;
   top: 0;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
}

.flag.left::before {
   left: 0;
   border-left: 170px solid var(--color);
}

.flag.right::before {
   right: 0;
   border-right: 170px solid var(--color);
}

.carousel .ribbon.right {
   right: 0px;
}

.bigBox .flag {
   transform: scale(1.2);
}

.smallBox .flag {
   transform: scale(0.9);
}

.smallBox .ribbon {
   transform: scale(0.8);
   top: -20px;
   right: -20px;
}

.bigBox .flag.left::before,
.smallBox .flag.left::before {
   left: 5px;
   border-left: 100px solid var(--color) !important;
}

.bigBox .flag.right::before,
.smallBox .flag.right::before {
   right: -5px;
   border-right: 100px solid var(--color) !important;
}

.serviceCard .flag {
   padding: 16px;
}

.serviceCard .flag.left::before {
   border-left: 130px solid var(--color);
}

.serviceCard .flag.right::before {
   border-right: 130px solid var(--color);
}

.serviceCard .flag.left::before,
.serviceCard .flag.right::before {
   height: 50px;
}

@media only screen and (max-width: 900px) {
   .carousel .flag.left {
      left: -18px !important;
      transform: scale(0.9);
   }
   .serviceCard .ribbon.left {
      left: 2px !important;
   }

   .serviceCard .ribbon.right {
      right: 2px !important;
   }
}

@media only screen and (max-width: 600px) {
   .serviceCard .flag.left {
      left: 0 !important;
   }

   .serviceCard .flag.right {
      right: 0 !important;
   }

   .serviceCard .ribbon.left {
      left: 8px !important;
   }

   .serviceCard .ribbon.right {
      right: 9px !important;
   }

   .bigBox .flag.left {
      left: -15px !important;
      transform: scale(1);
   }
}

@media only screen and (max-width: 500px) {
   .smallBox .flag.right {
      transform: scale(0.7);
      right: -15px;
   }

   .smallBox .ribbon.right {
      transform: scale(0.7);
      right: -25px;
      top: -25px;
   }
}
