.subHeaderContainer {
   height: 84px;
   position: relative;
   margin-bottom: 30px;
   background: transparent
      linear-gradient(90deg, #b40d29 0%, #ee3134 52%, #f58120 100%) 0% 0% no-repeat
      padding-box;
   .content {
      color: white;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-left: 50px;
      .text {
         font-size: 1.5rem;
         margin-left: 40px;
         margin-right: 33px;
      }
      .icon {
         img {
            position: absolute;
         }
      }
   }
}
