@import '../../../assets/styles/mixins';

.inputWrap {
   .inputInnerWrap {
      position: relative;
      height: 100%;
      width: 100%;
   }

   text-align: center;
}
