@import '../../../assets/styles/mixins';

.hoverIconWrap {
   position: relative;
   cursor: pointer;
   width: fit-content;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
   cursor: pointer;
   span {
      width: 100%;
      height: 100%;
      flex: 1;

      &:hover {
      }
   }
}

.hoverSection {
   display: flex;
   flex-direction: column;
   gap: 20px;
   animation: fadeIn 0.3s;
   position: absolute;
   top: 20px;
   left: 100px;
   height: auto;
   max-width: 600px;
   width: 500px;
   box-shadow: 0 0 30px #b9b9b9;
   object-fit: fill !important;
   z-index: 500;
   padding: 1rem 0.5rem;
   background-color: #000000b8;
   @include responsive(laptop) {
      left: -50px;
      max-width: 350px;
   }
   @include responsive(tablet) {
      left: -90px;
      max-width: 300px;
   }
   @include responsive(mobile) {
      max-width: 270px;
   }
}
.hidden {
   display: none;
}

.visible img {
   display: block;
   animation: fadeIn 0.3s;
   //    position: absolute;
   //    top: 20px;
   height: auto;
   width: 100%;
   object-fit: cover !important;
   z-index: 10;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}
