@import '../../../assets/styles/mixins';
.sticky {
   width: 100%;
   height: 50px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 5000;
   transition: all 0.3s ease;
   padding: 30px 0;
   @include responsive(mobile) {
      max-width: 550px;
      padding: 0 10px;
      box-shadow: none;
   }

   img {
      width: 65px;
      height: auto;
      transition: all 0.2s ease-in;
      @include responsive(mobile) {
         width: 55px;
         height: auto;
         transition: all 0.4s ease-in;
      }
   }

   .goBackHeaderLink {
      cursor: pointer;
      font-weight: 600;
      font-size: 0.9rem;
      color: #000000;
      background: none;
      border: none;
   }
}

.scrolled {
   width: 100%;
   height: 50px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 5000;
   box-shadow: -5px 5px 20px 1px #3332562e;
   transition: all 0.3s ease;
   @include responsive(mobile) {
      max-width: 550px;
      padding: 0 10px;
      box-shadow: none;
   }
   img {
      width: 55px;
      height: auto;
      transition: all 0.4s ease-in;
   }
}

.content {
   width: 100%;
   padding: 0 80px;
   margin: 10px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0 auto;
   padding-bottom: 5%;
   position: relative;
   height: 100%;
   max-width: 1640px;
   padding: 10px 100px;

   @include responsive(big-desktop) {
      max-width: 1640px;
   }
   @include responsive(laptop) {
      max-width: 1300px;
   }
   @include responsive(tablet) {
      max-width: 750px;
      padding: 0 40px;
   }

   @include responsive(mobile) {
      max-width: 550px;
      padding: 0 10px;
   }
}
