@import '../../../assets/styles/mixins';

.serviceImage {
   flex: 1 3 50%;
   img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: var(--radius);

      @include responsive(mobile) {
         height: 200px;
      }
   }
}
.serviceDescriptionWrap {
   padding: 1rem 0;

   .serviceDescription {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
   }

   .termsAndConditions {
      width: 100%;
      // height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .helpLinkWrap {
         display: flex;
         align-items: center;
         justify-content: center;
         svg {
            height: 1rem;
            margin-left: 5px;
         }
      }

      .descriptionIdText {
         display: flex;
         gap: 0.425rem;
         svg {
            display: block;
            height: 1rem;
            width: 1rem;
            flex: 1 0 auto;
         }
      }
   }
}
