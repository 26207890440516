.stepProgress {
   // background: #;
   width: 50px;
   height: 50px;
   border-radius: 80px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: gray;
   position: absolute;
   top: -20px;
   right: -20px;
   span {
      transition: all 0.3s ease-out;
      color: #a4bac5;
      display: inline-block;
      // background: white;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.8125rem;
      font-weight: 600;
      box-shadow: 0 1.9px 2.7px #00000002, 0 4.8px 6.9px #00000003,
         0 9.7px 14.2px #00000005, 0 20.1px 29.2px #00000005, 0 55px 80px #00000008;
   }
}

.completed {
   span {
      color: white;
      transition: all 0.3s ease-in;
      animation: rotation 0.7s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
   }

   .tickIcon {
      animation: opacity 0.9s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
   }
}

@keyframes rotation {
   from {
      // -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 1, 0, 0deg);
   }

   to {
      // -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 1, 0, 360deg);
   }
}

@keyframes opacity {
   from {
      opacity: 0;
      // -webkit-transform: rotate3d(0, 0, 1, 0deg);
   }

   to {
      opacity: 1;
      // -webkit-transform: rotate3d(0, 0, 1, 360deg);
   }
}
