@import '../../assets/styles/mixins';

.servicePageWrap {
   display: flex;
   gap: 50px;
   width: 100%;
   margin: 0 auto;

   .formErrorMsg {
      margin: 1rem 0;
      color: #ed1a3b;
      font-size: 1.5rem;
   }

   @include responsive(laptop) {
      // flex-direction: column;
      width: 100%;
      gap: 40px;
   }
   @include responsive(small-tablet) {
      flex-direction: column;
   }
   @include responsive(mobile) {
      width: 90%;
      gap: 0px;
   }

   .serviceForm {
      flex: 1 1 50%;
      height: 100%;
      padding: 0rem;

      .loader {
         text-align: center;
         margin: 1rem 0;
         display: block;
      }

      @include responsive(mobile) {
         padding: 2rem 0.5rem;
      }
   }
}
