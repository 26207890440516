.preloaderContainer {
   width: 100vw;
   height: 100vh;
   z-index: 9999;
   display: flex;
   align-items: center;
   justify-content: center;
   // position: absolute;
   position: fixed;
   top: 0;
   left: 0;
   // background-color: rgba(255, 255, 255, 0.85);
   background-color: #ffffff;
   background-color: #ffffff;

   &.default {
      background-color: #ffffff;
   }
   &.black {
      background-color: #ffffffe7;
      background-color: #ffffff;
   }
   &.red {
      background-color: #ffffff;
   }
}
