@import '../../../../assets/styles/mixins';

.goBackBtn {
   height: 50px;
   width: 50px;
   background: white;
   border-radius: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 20px;
   box-shadow: 0px 0px 30px #0000001a;
   cursor: pointer;
   transition: all 0.2s ease;
   z-index: 1000;
   svg {
      height: 20px;
   }
   @include responsive(big-desktop) {
      left: -50px;
      // top: 50px;
   }
   @include responsive(laptop) {
      left: 110px;
      top: 50px;
      height: 40px;
      width: 40px;
      svg {
         height: 15px;
      }
   }
   @include responsive(tablet) {
      left: 50px;
      top: 60px;
      height: 30px;
      width: 30px;
      svg {
         height: 15px;
      }
   }
   @include responsive(mobile) {
      left: 30px;
      top: 45px;
      height: 30px;
      width: 30px;
      svg {
         height: 15px;
      }
   }

   &:hover {
      background: #f7f7f7;
   }
   &:active {
      transform: scale(0.95);
   }
}
