.addInputWrap {
   width: 100%;
   height: auto;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   gap: 5px;
   margin-top: 5px;

   svg {
      width: 15px;
   }
}
.disabled {
   filter: grayscale(1);
   opacity: 0.3;
   cursor: not-allowed;
   pointer-events: none;
}
