// SWIPER
@import './mixins';
// Navigation arrows

.swiper-button-prev {
   color: #fff;
}

.swiper-button-next {
   color: #fff;
}

.swiper-pagination-bullet {
   transition: all 0.3s ease;
   opacity: 0.3;
   background: white;
}

.swiper-pagination-bullet-active {
   opacity: 1;
   background: var(--swiper-pagination-color, var(--swiper-theme-color));
   background: white;
   width: 30px;
   border-radius: 10px;
   opacity: 1;
   transition: all 0.3s ease;
}

.swiper-container-horizontal > .swiper-scrollbar {
   height: 3px;
   // background: rgb(243, 243, 243);
   background: none;
}

.swiper-scrollbar-drag {
   background: #e0b700;
   background: linear-gradient(315deg, #ea2027b0 0%, #ee5a24 74%);
   height: 100%;
}

.service-row-slider {
   padding: 0 0 3rem;
}

// Mobile
@include responsive(mobile) {
   // Swiper arrows
   .swiper-button-next,
   .swiper-button-prev {
      transform: scale(0.5);
   }
   .swiper-pagination-bullets {
      bottom: -17px !important;
   }
}

// REACT SELECT
.phones-dropdown {
   &__control {
      // width: 100%;
      // height: 45px;
      // padding: 0 1rem;
      // font-size: 1rem;
      // color: var(--font-color);
      // border: none !important;
      // border-radius: var(--radius-btn);
      // background-color: var(--input-bg) !important;
      // transition: box-shadow 0s;
      &--is-focused {
         // border: none !important;
         // box-shadow: none !important;
         // background-color: var(--input-bg) !important;
         // background-color: blue !important;
      }
   }

   &__menu {
      // background-color: var(--input-bg) !important;
   }

   &__option {
      &--is-focused {
         // border: none !important;
         // box-shadow: none !important;
         // background-color: #e6e3e3 !important;
      }
      &--is-selected {
         // background-color: #e6e3e3 !important;
         // color: var(--font-color) !important;
      }
   }

   &__indicator-separator {
      // display: none;
   }
}

// REACT TABS
.react-tabs__tab {
   border-bottom: 2px solid #fff;
   list-style: none;
   padding: 0 1rem 0.5rem 0;
   margin-right: 0.5rem;
   color: #373a3c;
   font-size: 1rem;
   font-weight: 500;
   cursor: pointer;
   max-width: 200px;
   min-width: 80px;

   &:hover {
      transition: 0.1s;
      color: #ed1a3b;
   }
}

.react-tabs__tab:focus {
   border-bottom: 2px solid red;
   box-shadow: none;
   outline: none;
}

.react-tabs__tab:focus:after {
   content: '';
   position: absolute;
   height: 0;
   left: -4px;
   right: -4px;
   bottom: -5px;
   border: none;
}
.react-tabs__tab--selected {
   border-bottom: 2px solid red;
   &:hover {
      transition: 0.1s;
      color: #ed1a3b;
   }
}

// TABS
.react-tabs__tab-panel--selected {
   position: relative;

   &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
   }

   &::-webkit-scrollbar-track {
      background: #e8e8e8;
      outline: none;
      border-radius: 20px;
   }

   &::-webkit-scrollbar-thumb {
      background: linear-gradient(-110deg, #ff8a00, #ff2f00c4);
      border-radius: 10px;
   }
}

// PIN INPUT
.pincode-input-container {
   margin-top: 0.2rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.pincode-input-text {
   background: #eeeeee !important;
   width: 60px !important;
   height: 60px !important;
   border: 1px solid #eeeeee !important;
   border-radius: 6px !important;
}
.pincode-input-text:focus {
   border: 1px solid #b4b9be !important;
}
