.container {
   display: flex;
   flex-direction: row;
   align-items: stretch;
   justify-content: center;
   height: fit-content;
   margin-top: 20px;
}

.bigBox {
   flex: 1;
   background-color: #1d1c1c;
   text-align: center;
   margin: 10px;
   height: 100%;
   position: relative;
   display: flex;
   flex-direction: column;
   height: initial;
   .image {
      height: 479px;
   }
   button {
      width: 48%;
   }
}

.smallBoxes {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   flex: 1;
   height: 100%;
   .smallImage {
      height: 181px;
   }
}

.smallBox {
   background-color: #1d1c1c;
   margin: 10px;
   flex: 1;
   position: relative;
   display: flex;
   flex-direction: column;
   height: inherit;
}

.row {
   display: flex;
   flex-direction: row;
   flex: 1;
}

.col {
   display: flex;
   flex-direction: column;
   flex: 1;
}

.image {
   width: 100%;
}
.smallImage {
   width: 100%;
}

.contentWrapperBigBox {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 27px;
   background-color: #1d1c1c;
   flex: 1;
}

.contentWrapper {
   padding: 12px;
   background-color: #1d1c1c;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   flex: 1;
}

.imageContainer {
   position: relative;
   // display: inline-block;
}
.hoverGifBigBox {
   position: absolute;
   top: 0;
   left: 0;
   height: 479px;
   width: 100%;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
   z-index: 999;
}

.hoverGif {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 181px;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
   z-index: 8;
}

.smallBox:hover .hoverGif {
   opacity: 1;
}
.smallBox:hover {
   transform: scale(1.3);
   transition: transform 0.5s ease;
   z-index: 1001;
}
.imageContainer:hover .hoverGifBigBox {
   opacity: 1;
}

@media only screen and (max-width: 900px) {
   .container {
      display: block;
   }

   .smallBox:hover {
      transform: none !important;
   }
}

@media only screen and (max-width: 600px) {
   .bigBox {
      .image {
         height: 200px;
      }
      .hoverGifBigBox {
         height: 200px;
      }
   }

   .smallBox {
      .smallImage {
         height: 150px;
      }
      .hoverGif {
         height: 150px;
      }
      .contentWrapper {
         div {
            font-size: 1rem;
         }
         button {
            height: 35px;
            font-size: 0.8rem;
         }
      }
   }
}

@media only screen and (max-width: 450px) {
   .smallBox {
      .smallImage {
         height: 130px;
      }
      .hoverGif {
         height: 130px;
      }
   }
}
