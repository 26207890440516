.confirmationModal {
   background-color: #fff;
   backdrop-filter: blur(2px);
   /* border-radius: 15px !important; */
   padding: 2rem 2rem 0.5rem 2rem;
   margin: inherit !important;
   position: relative;
   min-height: fit-content !important;
   min-width: 700px !important;
}

.modalHeader {
   display: flex;
   justify-content: space-between;
   column-gap: 1rem;
}

.modalWrap {
   padding: 2rem 0 0 0;

   p {
      font-size: 1.2rem !important;
   }

   .buttonsWrap {
      margin-top: 3rem;
      display: flex;
      padding: 1rem 0;
      column-gap: 0.6rem;
      justify-content: flex-end;

      button {
         cursor: pointer;
         padding: 8px 25px;
      }
   }
}

@media screen and (max-width: 600px) {
   .confirmationModal {
      width: 95% !important;
   }

   .closeIcon {
      width: 18px;
   }

   .modalHeader h2 {
      font-size: 1.3rem !important;
   }

   .modalWrap p {
      font-size: 1rem !important;
   }
}
