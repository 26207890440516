@import '../../../assets/styles/mixins';

.slide {
   width: 100%;
   height: 100%;

   .slideImage {
      animation: opacity 0.9s 1 forwards;
   }

   .slideTextWrap {
      position: absolute;
      top: 25%;
      right: 10%;
      width: 50%;
      height: auto;
      position: absolute;
      z-index: 20;
      background: #0000007a;
      padding: 2.5rem;
      @include responsive(laptop) {
         width: 50%;
      }
      @include responsive(tablet) {
         width: 50%;
      }
      @include responsive(mobile) {
         width: 80%;
         left: 10%;
         top: 10%;
         background: none;
         padding: 1.5rem;
      }

      .slideTitle {
         margin-bottom: 0.75rem;
         transition: all 0.2s;
      }

      .slideDescription {
         line-height: 1rem;
      }
   }

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      @include responsive(mobile) {
         object-position: left;
         filter: brightness(0.2);
      }
   }
}

@keyframes opacity {
   from {
      opacity: 0;
      // -webkit-transform: rotate3d(0, 0, 1, 0deg);
   }

   to {
      opacity: 1;
      // -webkit-transform: rotate3d(0, 0, 1, 360deg);
   }
}
