@import '../../../../assets/styles/mixins';

.layout {
   // background-color: rgb(0, 255, 64);

   @include responsive(big-desktop) {
      max-width: 1640px;
   }
   @include responsive(laptop) {
      max-width: 1300px;
   }
   @include responsive(tablet) {
      max-width: 750px;
      padding: 0 40px;
   }

   @include responsive(mobile) {
      max-width: 550px;
      padding: 0 10px;
   }
}
