.disabled {
   background: #a4a4a4 !important;
   cursor: not-allowed !important;
}

.arrowIcon {
   width: 0.5rem;
}

.topUpIcon {
   margin-right: 5px;
}
