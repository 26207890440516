.loaderWrap {
   position: relative;
   height: 43px;
   width: 62px;
   transform-style: preserve-3d;
   animation: loader 1s infinite linear;

   .loaderBlack {
      position: absolute;
      top: -60%;
      left: 120%;
      transform: rotateY(0deg) translateZ(10px) scale(2);
      width: 31px;
      height: 18px;
      background: #000;
   }
   .loaderRed {
      position: absolute;
      transform: rotateY(0) translateZ(-15px) scale(2);
      left: 0;
      width: 62px;
      height: 43px;
      background: #da291c;
   }
}

@keyframes loader {
   0% {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
   }
   100% {
      -webkit-transform: rotateY(-359deg);
      transform: rotateY(-359deg);
   }
}
