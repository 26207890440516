.errorLabelContainer {
   color: #ed1a3b;
   // Size

   &.small {
      text-align: left;
      font-weight: 400;
      position: relative;
      font-size: 0.8rem;
      color: #f01111;
   }
   &.medium {
      margin: 1rem 0;
      color: #ed1a3b;
      font-size: 1.5rem;
   }
   &.large {
      font-size: 3rem;
   }
   // Positon

   .left {
      text-align: left;
   }
   .center {
      text-align: center;
   }
   .right {
      text-align: right;
   }
}

// .left {
//    text-align: left;
// }
// .center {
//    text-align: center;
// }
// .right {
//    text-align: right;
// }
// .smallText {
//    font-size: 1rem;
// }
// .mediumText {
//    font-size: 1.3rem;
// }
// .bigText {
//    font-size: 1.5rem;
// }
