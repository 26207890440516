@import '../../../../assets/styles/mixins';

.col {
   position: relative;
   margin: 0 0 3rem 0;
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
   flex: 1 1 100%;
   padding: 3rem 2rem;
   border: 1px solid rgba(255, 0, 0, 0);

   @include responsive(mobile) {
      padding: 3rem 1rem;
   }

   &.error {
      border: 2px solid red;
   }
}
