@mixin responsive($breakpoint) {
   @if $breakpoint == small-mobile {
      @media only screen and (max-width: 350px) {
         @content;
      }
   }
   @if $breakpoint == mobile {
      @media only screen and (max-width: 600px) {
         @content;
      }
   }
   @if $breakpoint == small-tablet {
      @media only screen and (max-width: 700px) {
         @content;
      }
   }
   @if $breakpoint == tablet {
      @media only screen and (max-width: 900px) {
         @content;
      }
   }
   @if $breakpoint == laptop {
      @media only screen and (max-width: 1400px) {
         @content;
      }
   }
   @if $breakpoint == big-desktop {
      @media only screen and (min-width: 1800px) {
         @content;
      }
   }
}
