@import '../../../assets/styles/mixins';

.pageTitleContainer {
   position: relative;
   height: 60px;
   .pageLabel {
      margin-left: 70px;
      font-size: 1.8rem;
      font-weight: 500;
      position: relative;
   }
}

.goBackBtnActive {
   top: -5px;
   height: 40px;
   width: 40px;
   @include responsive(big-desktop) {
      left: 0px;
      svg {
         height: 15px;
      }
   }
   @include responsive(laptop) {
      left: 0px;
      svg {
         height: 15px;
      }
   }
   @include responsive(laptop) {
      left: 10px;
      // top: 0px;
      height: 40px;
      width: 40px;
      svg {
         height: 15px;
      }
   }

   @include responsive(tablet) {
      left: 10px;
      svg {
         height: 15px;
      }
   }
}
