.pinInputWrap {
   display: flex;
   flex-direction: column;
   gap: 0.6rem;
   flex: 1 1 100%;
   position: relative;

   .pinLoader {
      margin: 0 auto;
      text-align: center;
      margin-top: 1rem;
   }
}
