.backButtonContainer {
   position: relative;
   height: 20px;
}
.userInfo {
   display: flex;
   width: 100%;
   justify-content: space-between;
   padding: 50px 0px;
   .noIconsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.4;
      margin-top: 20px;
   }
   .noIconsContainer img {
      width: 80px;
   }

   .showMoreBtn {
      @extend .signOutButton;
      width: 50% !important;
      margin: 0 auto !important;
      margin-top: 30px !important;
   }

   @media only screen and (max-width: 1024px) {
      flex-direction: column;
      row-gap: 30px;
      padding: 10px 30px;
   }

   .firstColumn {
      @media only screen and (min-width: 1024px) {
         width: 25%;
      }

      display: flex;
      flex-direction: column;
      row-gap: 20px;

      p {
         height: fit-content;
      }

      h2 {
         font-weight: bold;
         color: white;
      }

      span {
         color: red;
         font-weight: bold;
      }

      .balanceLabel {
         color: white;
      }

      .balanceContainer {
         display: flex;
         align-items: center;
         column-gap: 15px;

         .topUpButton {
            width: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            font-size: 0.9rem;
         }
      }

      .signOutButton {
         border: 1px solid #929292;
         border-radius: 7px;
         padding: 4px;
         letter-spacing: 0.44px;
         font-weight: 600;
         background-color: #0d0d0d;
         max-width: 50%;
      }

      .companyName {
         display: inline-block;
         margin-bottom: 15px;
         color: #ffffff7f;
         font-size: 18px;
      }

      .companyIcon {
         fill: #ffffff7f;
      }
   }

   .loaderWrapper {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
         display: flex;
         margin: auto;
      }
   }

   .secondColumn {
      @media only screen and (min-width: 1024px) {
         width: 30%;
      }
      background-color: #0d0d0d;
      border-radius: 20px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      min-height: 230px;

      .serviceTile {
         display: flex;
         padding-bottom: 15px;
         margin-top: 15px;
         border-bottom: 2px solid #dfdfdf25;

         .serviceImage {
            width: 75px;
            margin-right: 15px;
            margin-left: 15px;
            border-radius: 5px;
         }

         .serviceData {
            display: flex;
            justify-content: space-between;
            row-gap: 0.3rem;
            flex-direction: column;

            .dateType {
               font-size: 12px;
               color: #aaaaaa;
            }

            .serviceName {
               font-size: 18px;
               color: white;
               text-transform: uppercase;
            }

            .servicePrice {
               color: #d9291a;
               font-size: 16px;
            }

            .unsubscribeLink {
               color: #aaaaaa;
               font-size: 16px;
               text-decoration: underline;
               cursor: pointer;
            }
         }
      }

      :last-child {
         border-bottom: none;
      }
   }

   .transactionsColumn {
      @media only screen and (min-width: 1024px) {
         width: 35%;
      }

      @media only screen and (max-width: 500px) {
         padding-bottom: 50px;
      }

      border-radius: 20px;
      background-color: #0d0d0d;
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding-bottom: 30px;
      min-height: 230px;

      .transactionItem {
         padding: 15px;
         display: flex;
         justify-content: space-between;
         border-bottom: 2px solid #222222;
         column-gap: 5px;
         align-items: center;

         .transactionIcon {
            margin-right: 20px;

            path {
               fill: #ffffff7f;
            }
         }

         .transactionDetails {
            margin-right: auto;
            width: 50%;
            overflow-wrap: break-word;
         }

         .lowOpacityDate {
            color: #c4c4c4;
            font-size: 0.7rem;
            font-weight: 600;
            margin-bottom: 3px;
         }

         .name {
            color: #c5c4ff;
            font-weight: bold;
         }

         .positive,
         .negative {
            display: flex;
            align-items: center;
            column-gap: 5px;
         }

         .negative {
            color: #c02518;
            font-weight: 600;

            path {
               fill: #c02518;
            }
         }

         .positive {
            color: #7ee750;
            font-weight: 600;

            path {
               fill: #7ee750;
            }
         }
      }

      :last-child {
         border: none;
      }
   }

   .title {
      border-radius: 20px 20px 0 0;
      padding: 15px;
      font-weight: bold;
      color: #7a7a7a;
   }
   .noActiveServiceTitle {
      border-radius: 20px 20px 0 0;
      padding: 15px;
      color: #7a7a7a;
      font-size: 15px;
      text-align: center;
      opacity: 0.6;
   }
}

@media screen and (max-width: 600px) {
   .topUpButton {
      width: 100px !important;
      height: 35px !important;
      font-size: 0.8rem !important;
   }
}
