.inputWrap {
   display: flex;
   flex-direction: column;
   gap: 0.6rem;
   flex: 1 1 100%;
}

.error {
   :global {
      .phones-dropdown {
         border: 1px solid #ed1a3b !important;
         &__control {
            border: 1px solid #ed1a3b !important;
         }
      }
   }
}
