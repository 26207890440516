@import '../../assets/styles/mixins';

.categoryLink {
   transition: all 0.2s ease;
   cursor: pointer;
}

.categoryLinkActive {
   transition: all 0.2s ease;
}
